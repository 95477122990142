<template>
  <div class="relative">
    <ValidationProvider tag="div" :name="selectName" :rules="rules">
      <label
        v-if="selectLabel"
        :for="selectName"
        class="mb-10 block font-semibold cursor-pointer"
      >
        {{ selectLabel }} <span v-if="required || required === ''" class="text-red-100">*</span>
      </label>

      <v-select
        :id="selectName"
        :clearable="clearable"
        :searchable="false"
        v-bind="$attrs"
        class="el-select bg-white"
        v-on="{ ...$listeners }"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="icon-arrow-down text-grey text-xs" />
        </template>
      </v-select>
    </ValidationProvider>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import '@/assets/styles/plugins/vue-select.css'

export default {
  name: 'ElSelect',

  components: { vSelect },

  props: {
    selectClass: { type: String, default: '' },

    selectName: { type: String, default: '' },

    rules: { type: [String, Object], default: '' },

    selectLabel: { type: String, default: '' },

    required: {
      type: [String, Boolean],
      default: false
    },

    clearable: { type: Boolean, default: false }
  },

  data () {
    return {
      showPassword: false
    }
  },

  computed: {
    isDisabled () {
      return this.$attrs.disabled ? 'cursor-not-allowed bg-grey-100' : ''
    }
  }
}
</script>

<style lang="scss">
  .el-select {
    .vs__dropdown-toggle,
    .style-chooser .vs__search::placeholder,
    .style-chooser .vs__dropdown-toggle,
    .style-chooser .vs__dropdown-menu {
      @apply border border-grey-200 px-12 py-8 outline-none text-base leading-big overflow-hidden rounded text-grey font-main;
      font-size: 14px;
    }

    .vs__dropdown-menu {
      @apply border border-grey-200 rounded;
    }

    .vs__search, .vs__selected-options, .vs__selected {
      @apply mt-0 text-grey font-main leading-normal pl-0 ml-0;
    }

    .vs__selected {
      @apply bg-transparent border-0;
    }

    .vs__actions {
      padding: 0;
    }

    &::placeholder {
      color: #6e7583;
    }
    :-ms-input-placeholder {
      color: #6e7583;
    }
    ::-ms-input-placeholder {
      color: #6e7583;
    }
  }
</style>
